.container {
    margin-top: 5rem;
    padding: 20px;
    max-width: 1200px; 
  }
  
  .terms-box {
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    padding: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  }
  
  .heading-main {
    text-align: left;
    font-size: 1.25rem;
    margin-bottom: 28px;
  }
  
  .heading-title {
    text-align: center;
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  
  .paragraph-main {
    font-size: 1.125rem;
    text-align: justify;
    margin-bottom: 24px;
  }
  
  .summary-section {
    margin-bottom: 24px;
  }
  
  .heading-subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .paragraph-summary {
    font-size: 1.125rem;
    text-align: justify;
    margin-bottom: 24px;
  }
  
  .contact-section {
    margin-bottom: 24px;
  }
  
  .paragraph-contact {
    font-size: 1.125rem;
    text-align: justify;
    margin-bottom: 16px;
  }
  
  .contact-item {
    font-size: 1.125rem;
    text-align: justify;
    margin-bottom: 8px;
  }
  
  a {
    text-decoration: none;
    color: #b398ce; 
  }
  
  a:hover {
    text-decoration: underline;
    color:  #7e6eb8; 
  }
  

  @media (max-width: 768px) {
    .terms-box {
      padding: 20px; 
    }
    .heading-main {
      font-size: 1.125rem; 
    }
    .heading-title {
      font-size: 1.5rem; 
    }
    .paragraph-main, .paragraph-summary, .paragraph-contact {
      font-size: 1rem; 
    }
  }
  