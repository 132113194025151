.navigation-arrows {
    display: flex; 
    justify-content: space-between; 
    position: absolute; 
    width: 100%; 
    top: 50%; 
    transform: translateY(-50%); 
    z-index: 10; 
  }
  
  .navigation-arrows button {
    font-size: 2rem; 
    cursor: pointer; 
    color: #652D90; 
    background: none; 
    border: none;
    outline: none; 
    transition: opacity 0.3s; 
  }
  
  .navigation-arrows button:disabled {
    opacity: 0.2; 
  }
  
  .prev-arrow {
    position: absolute;
    left: -30px; 
    font-size: 24px;
  }
  
  .next-arrow {
    position: absolute;
    right: -30px;
    font-size: 24px;
  }
  