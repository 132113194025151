body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Lato', sans-serif;
  color: #333;
  background: #f5f5f5;
  margin-top: -1rem;
}


@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 12px;
    position: relative;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b398ce;
    border-radius: 12px;
    border: 2px solid #f1f1f1;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}
