.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: fixed; 
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  .nav-logo {
    width: 130px;
    height: auto;
  }
  
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .nav-items {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 1rem;
    font-weight: 600;
  }
  
  .nav-link {
    text-decoration: none;
    color: #3C3D37;
    font-size: 1rem;
    transition: color 0.3s ease;
    display: flex;  
    align-items: center;  
    gap: 2px;  
  }

  .nav-link:hover {
    color: #b398ce; 
  }

  .nav-links {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    transition: color 0.3s ease;
    display: flex;  
    align-items: center;  
    justify-content: center; 
    text-align: center;
  }

  .nav-links:hover {
    color: #b398ce; 
    text-decoration: none;
  }
  
  .nav-badge {
    text-decoration: none;
    color: #333;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease; 
  }
  
  .nav-badge:hover {
    color: #b398ce; 
  }
  
  .dropdown {
    position: absolute; 
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1; 
    margin: 20px;
    right: 0;
  }
  
  .dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown li {
    padding: 0.5rem 0.8rem;
  }
  
  .dropdown li:hover {
    background-color: #f1f1f1; 
    width: 100%;
  }
  
  .sampler-button {
    padding: 0.6rem 1.5rem;
    background-color: #b398ce;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sampler-button:hover {
    background-color: #412D41;
    color: #f0f0f0;
    transform: translateY(-2px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .sampler-button:active {
    transform: translateY(0);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .hamburger {
    display: none;
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 10;
  }
  
  @media (max-width: 820px) {
    .nav {
      padding: 1rem;
    }
  
    .nav-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  
    .nav-items {
      flex-direction: column;
      display: none; 
      gap: 1.5rem;
      width: 100%;
    }
  
    .nav-items-active {
      display: flex; 
    }
  
    .nav-link, .sampler-button {
      font-size: 1rem;
    }
  
    .hamburger {
      display: block;
    }
  }
  
  @media (max-width: 480px) {
    .nav-logo {
      width: 120px;
    }
  
    .nav-items {
      gap: 1rem;
    }
  
    .nav-link, .sampler-button {
      font-size: 0.9rem;
    }
  
    .sampler-button {
      padding: 0.4rem 0.8rem;
    }
  
    .nav-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  
    .hamburger {
      font-size: 1.5rem;
    }
  }
  