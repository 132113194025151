.close-icon {
    position: absolute;
    right: 10px;
    top: 0;
    /* padding: 10px; */
    z-index: 999;
    cursor: pointer;
    font-size: 25px;
    color: white;
  }

  @media only screen and (max-width: 992px){
#redeemText{
  margin: 0rem 1rem;
  text-align: center;
}
  }