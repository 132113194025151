#category {
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;  
}

#category::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  transition: transform 0.3s ease;
  margin-left: 10px;
}

#category.open::after {
  transform: rotate(180deg);
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #E9E9E9;
  position: fixed;
  z-index: 1;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden; 
  box-sizing: border-box;
  left: 0;
  top: auto;
  max-height: 80%;  
  overflow-y: scroll;  
}

.filter-option, .all-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
}

.filter-option:not(:last-child) {
  border-right: 1px dotted black;
}

.all-option {
  border-right: 1px dotted black;
}

.filter-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.custom-checkbox {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  background-color: #fff;
  display: inline-block;
  margin-left: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  box-sizing: content-box;
  position: relative;
}

.custom-checkbox.checked {
  border-color: #ccc;
  background-color: #fff;
}

.custom-checkbox.checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #A256D2;
  border-radius: 1px;
  transform: translate(-50%, -50%);
}

.filter-description {
  font-size: 0.85rem;
  color: #666;
  margin-top: 12px;
  text-align: left;
  width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.read-more {
  color: #A256D2;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}

.read-more:hover {
  color: #A256D2;
}

@media (max-width: 820px) {
  .dropdown-container {
    flex-direction: column;
    width: 100%;
    top: auto;
    padding: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .filter-option, .all-option {
    width: 100%;
    padding: 12px 16px;
  }

  .filter-option:not(:last-child) {
    border-right: none;
    border-bottom: 1px dotted black;
  }

  .all-option {
    border-right: none;
  }

  .filter-description {
    font-size: 0.9rem;
    margin-top: 1px;
  }

  #category {
    padding: 12px;
    font-size: 1rem;
    margin: 0;
  }

  #category::after {
    margin-left: 8px;
  }
}

@media (max-width: 480px) {
  .dropdown-container {
    top: auto;
    transform: translateX(0);
    width: 100%;
    padding: 10px;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .filter-option, .all-option {
    padding: 10px;
    font-size: 0.9rem;
  }

  .filter-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-checkbox {
    width: 12px;
    height: 12px;
  }

  .filter-description {
    font-size: 0.8rem;
    margin-top: 2px;
    text-align: left;
    width: 100%;
    word-wrap: break-word;
  }

  #category {
    font-size: 0.8rem;
    margin: 0;
  }

  #category::after {
    margin-left: 6px;
  }
}

@media (max-width: 320px) {
  .dropdown-container {
    top: auto;
    transform: translateX(0);
    width: 100%;
    padding: 8px;
    max-height: 70%;
    overflow-y: auto;
  }

  .filter-option, .all-option {
    padding: 8px;
    font-size: 0.85rem;
  }

  .filter-description {
    font-size: 0.75rem;
    margin-top: 6px;
    text-align: left;
  }

  #category {
    font-size: 0.9rem;
    margin: 0;
  }

  #category::after {
    margin-left: 4px;
  }
}
